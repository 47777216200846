// TODO: add a way to convert from the input value to the output value.
export interface PreSubmitOutput {
    topic: string
    payload: string
  }
  
export type PreSubmitFunction = (message: MqttMessage) => PreSubmitOutput;
  // e.g. the set point for the motor is in steps and it should be translated to mm

export interface MqttValue {
  unit: string
  topic: string
  title: string
  editable?: boolean
  // a function shich runs pre submit so non default behaviour can be put in for the specific topic.
  // mostly needed for the motor controller.
  preSubmitFunction?: PreSubmitFunction
  decimalPlaces?: number
  // conversion value which translates the received value to another value by multiplication
  // e.g. conversionValue = 0.5 received value = 3 then displayed value is 1.5
  // This also works in reverse when a number is updated and published
  conversionValue?: number
}

export interface MqttContainer {
  title: string
  prefix: string
  values: (MqttValue | MqttContainer)[]
}

export interface MqttMessage {
    topic: string
    payload?: number
}

export interface MqttSubscription {
    title: string
    topic: string
    subscribed: boolean
}

export function instanceOfMqttValue(object: MqttValue | MqttContainer): object is MqttValue {
    const checkableObject: any = object as any
    if (checkableObject?.unit !== undefined && checkableObject?.topic !== undefined) {
        return true
    }
    return false
}