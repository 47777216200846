export const HEARTRATE_MIN = 50
export const HEARTRATE_MAX = 200

export const CADENCE_MAX = 200

export const POWER_MAX = 200

export const TORQUE_MAX = 200

export const valueAsPercentage = (value: number, max: number, min = 0): number => {
    if (value - min >= 0) {
        return (value - min) / (max - min) * 100
    } else {
        return 0
    }    
}