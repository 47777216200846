
import type { MqttContainer, MqttValue } from "./types"
import { motorControllerPreSubmit, motorConversionValue } from "./motorcontroller"

export const handlebarCurrentSetPointX: MqttValue = {
    title: 'Current Setpoint X',
    unit: 'mm',
    topic: 'bikefitting/motorcontroller/3/current_setpoint',
    editable: true,
    preSubmitFunction: motorControllerPreSubmit,
    conversionValue: motorConversionValue,
}

export const handlebarCurrentSetPointY: MqttValue = {
    title: 'Current Setpoint Y',
    unit: 'mm',
    topic: 'bikefitting/motorcontroller/2/current_setpoint',
    editable: true,
    preSubmitFunction: motorControllerPreSubmit,
    conversionValue: motorConversionValue,
}

export const handlebarCurrentSetPoints: MqttContainer = {
    title: "Handlebar Current Setpoint",
    prefix: '',
    values: [
        handlebarCurrentSetPointX,
        handlebarCurrentSetPointY,
    ],
}

export const handlebarCurrentPositionX: MqttValue = {
    title: 'Current Position X',
    unit: 'mm',
    topic: 'bikefitting/motorcontroller/3/current_pos',
    conversionValue: motorConversionValue,
}

export const handlebarCurrentPositionY: MqttValue = {
    title: 'Current Position Y',
    unit: 'mm',
    topic: 'bikefitting/motorcontroller/2/current_pos',
    conversionValue: motorConversionValue,
}

export const handlebarCurrentPosition: MqttContainer = {
    title: "Handlebar Current Position",
    prefix: '',
    values: [
        handlebarCurrentPositionX,
        handlebarCurrentPositionY,
    ],
}

export const handlebarDefinitions: MqttContainer = {
    title: 'Handlebar',
    prefix: 'motorcontroller/',
    values: [
        handlebarCurrentSetPoints,
        handlebarCurrentPosition,
    ],
}

export default handlebarDefinitions
