import { MqttContainer } from "./types"

import handlebarDefinitions from "./handlebar"
import saddleDefinitions from "./saddle"

// Temporary use of motorvalues for xy tool status indicator - replace with actual xy-tool definitions when available

export const xyToolDefinitions: MqttContainer = {
    title: 'XY-Tool',
    prefix: "xytool/",
    values: [
        handlebarDefinitions,
        saddleDefinitions,
    ],
}

export default xyToolDefinitions
