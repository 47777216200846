import { defineStore } from "pinia"
import { useMqttStore } from "@/store/mqttStore"
import { MqttValue, MqttMessage } from '../mqttDefinitions'

import { handlebarCurrentPositionX, handlebarCurrentPositionY, saddleCurrentPositionX, saddleCurrentPositionY } from "@/mqttDefinitions"
import { saddleCurrentSetPointX, saddleCurrentSetPointY, handlebarCurrentSetPointX, handlebarCurrentSetPointY } from "@/mqttDefinitions"

const numberFormatter = new Intl.NumberFormat("en-US", {
    signDisplay: "auto",
})

interface position {
    handlebarX: number
    handlebarY: number
    saddleX: number
    saddleY: number
}

export const usePositionStore = defineStore({

    id: "locationStore",
    state: () => ({
        // Start position as recieved from BFC/entered by bike dealer
        startPosition: {
            handlebarX: 217,
            handlebarY: 733,
            saddleX: 479,
            saddleY: 644,
        } as position,
        
        // Difference between motorPosition and absolutePosition
        positionOffset: {
            handlebarX: 60,
            handlebarY: 80,
            saddleX: 60,
            saddleY: 60,
        } as position,

        // Difference between measuredPosition and absolutePosition
        positionDeviation: {
            handlebarX: 0,
            handlebarY: 0,
            saddleX: 0,
            saddleY: 0,
        } as position,

    }),

    getters: {
        // Current motor position as recieved from the controller (in mm!)
        motorPosition(): position {
            const mqttStore = useMqttStore()
            return {
                handlebarX: mqttStore.getMessageOnTopic(handlebarCurrentPositionX),
                handlebarY: mqttStore.getMessageOnTopic(handlebarCurrentPositionY),
                saddleX: mqttStore.getMessageOnTopic(saddleCurrentPositionX),
                saddleY: mqttStore.getMessageOnTopic(saddleCurrentPositionY),
            } as position
        },

        // Current position as measured by the XY-tool
        measuredPosition(): position {
            return {
                handlebarX: this.absolutePosition.handlebarX + this.positionDeviation.handlebarX,
                handlebarY: this.absolutePosition.handlebarY + this.positionDeviation.handlebarY,
                saddleX: this.absolutePosition.saddleX + this.positionDeviation.saddleX,
                saddleY: this.absolutePosition.saddleY + this.positionDeviation.saddleY,
            } as position
        },

        // Current position as determined by the motor position and the corresponding offset
        // Since the handlebar X moves towards the 0 point, it's value is subtracted from the offset
        absolutePosition(): position {
            return {
                handlebarX: this.positionOffset.handlebarX - this.motorPosition.handlebarX,
                handlebarY: this.positionOffset.handlebarY + this.motorPosition.handlebarY,
                saddleX: this.positionOffset.saddleX + this.motorPosition.saddleX,
                saddleY: this.positionOffset.saddleY + this.motorPosition.saddleY,
            } as position
        },
        
        // Difference between startPosition and absolutePosition 
        positionChange(): any {
            return {
                handlebarX: numberFormatter.format(this.absolutePosition.handlebarX - this.startPosition.handlebarX),
                handlebarY: numberFormatter.format(this.absolutePosition.handlebarY - this.startPosition.handlebarY),
                saddleX: numberFormatter.format(this.absolutePosition.saddleX - this.startPosition.saddleX),
                saddleY: numberFormatter.format(this.absolutePosition.saddleY - this.startPosition.saddleY),
            } as any
        },
    },

    actions: {
        submitPosition(definition: MqttValue, newValue: number) {
            const mqttStore = useMqttStore()
            mqttStore.doPublish({
                topic: definition.topic,
                payload: newValue,
            } as MqttMessage, 
            definition,
            mqttStore.client)
        },

        calibratePosition() {
            // Add the difference to the offset to match 
            this.positionOffset.handlebarX += this.positionDeviation.handlebarX
            this.positionOffset.handlebarY += this.positionDeviation.handlebarY
            this.positionOffset.saddleX += this.positionDeviation.saddleX
            this.positionOffset.saddleY += this.positionDeviation.saddleY

            // Move the handlebar and saddle back to their original position if possible (motorvalue > 0)
            // Since the handlebar X moves towards the 0 point, it's value is added to the deviation
            this.submitPosition(handlebarCurrentSetPointX, this.motorPosition.handlebarX + this.positionDeviation.handlebarX)
            this.submitPosition(handlebarCurrentSetPointY, this.motorPosition.handlebarY - this.positionDeviation.handlebarY)
            this.submitPosition(saddleCurrentSetPointX, this.motorPosition.saddleX - this.positionDeviation.saddleX)
            this.submitPosition(saddleCurrentSetPointY, this.motorPosition.saddleY - this.positionDeviation.saddleY)

            // Reset the difference
            this.positionDeviation.handlebarX = 0
            this.positionDeviation.handlebarY = 0
            this.positionDeviation.saddleX = 0
            this.positionDeviation.saddleY = 0
        },

        moveToStartPosition() {
            this.submitPosition(handlebarCurrentSetPointX, (this.startPosition.handlebarX - this.positionOffset.handlebarX) * -1)
            this.submitPosition(handlebarCurrentSetPointY, this.startPosition.handlebarY - this.positionOffset.handlebarY)
            this.submitPosition(saddleCurrentSetPointX, this.startPosition.saddleX - this.positionOffset.saddleX)
            this.submitPosition(saddleCurrentSetPointY, this.startPosition.saddleY - this.positionOffset.saddleY)
        },
    },
})